import React from 'react';
import { Link } from "gatsby";

function Home(props) {

    return (
        <div>
            <div className="container">
                <div className="text-align-center">
                    <h2 className="home-topic-title">Top Travel Destinations</h2>
                    <p>Get inspiration from our top Travel Guides and sail to a new adventure abroad.</p>
                </div>

                <div className="row" style={{ marginBottom: '40px' }}>
                    <RenderTopTravel />
                </div>

                <div className="text-align-center">
                    <h2 className="home-topic-title">Top Travel Tips</h2>
                    <p>Travel with safety and make sure to use best available tips on your travels.</p>
                </div>

                <div className="row" style={{ marginBottom: '60px' }}>
                    <RenderTopTips />
                </div>
            </div>
        </div>
    );
}

function RenderTopTravel(props) {
    const topTravel = [
        {
            title: "Vietnam",
            link: "/travel-guides/asia/vietnam",
            image: "https://live.staticflickr.com/65535/50493711258_4c9594632a_o.jpg",
        },
        {
            title: "Paris",
            link: "/travel-guides/europe/paris",
            image: "https://live.staticflickr.com/65535/50494371573_6f48a53457_o.jpg"
        },
        {
            title: "Havana",
            link: "/travel-guides/north-america/havana",
            image: "https://live.staticflickr.com/65535/50495093076_0d73834441_o.jpg"
        },
        {
            title: "Budapest",
            link: "/travel-guides/europe/budapest",
            image: "https://live.staticflickr.com/65535/50495076261_bec7080a3a_o.jpg"
        },
        {
            title: "Miami",
            link: "/travel-guides/north-america/miami",
            image: "https://live.staticflickr.com/65535/50495093061_30622131d5_o.jpg"
        },
        {
            title: "Scotland",
            link: "/travel-guides/europe/scotland",
            image: "https://cdn.pixabay.com/photo/2016/11/29/09/29/mountains-1868715_1280.jpg"
        }
    ];

    return topTravel.map(({ title, image, link }) => {
        return (
            <div key={ title } className="col-6 col-md-4" style={{ padding: '0px', margin: '0px', overflow: 'hidden'}}>
                <div className="home-country-card">
                    <Link to={ link }>
                        <div className="home-country-card-image" style={{ backgroundImage: `url(${ image })` }} />
                        <div className="header-text">
                            <p className="home-country-text">{ title }</p>
                        </div>
                    </Link>
                </div>
            </div>
        )
    });
}

function RenderTopTips(props) {
    const tips = [
        {
            title: <p>Flight<br/>Deals</p>,
            image: "https://live.staticflickr.com/65535/50495276257_a3d7502c28_o.jpg",
            description: <p>See how you can search for cheap flights and get the best deals.<br/><br/>Save money in one of the biggest travel expenses.</p>,
            link: "/travel-tips/flight-deals"
        },
        {
            title: <p>Visas</p>,
            image: "https://live.staticflickr.com/65535/50494412803_8516fb2792_o.jpg",
            description: <p>Traveling to a foreign country?<br/><br/>See what you need to do to get your entry visa.</p>,
            link: "/travel-tips/visas"
        },
        {
            title: <p>Travel<br/>Insurance</p>,
            image: "https://live.staticflickr.com/65535/50495276227_97213a0cce_o.jpg",
            description: <p>Travel with safety and security by opting for one of the best travel insurances on the market.</p>,
            link: "/travel-tips/insurance"
        }
    ];

    return tips.map(({title, image, description, link}) => {
        return (
            <React.Fragment key={ image }>
                <div className="col-12 col-md-6 col-lg-4" style={{ padding: '0px', margin: '0px',  marginTop: '30px', overflow: 'hidden' }} >
                    <div className="home-tips-card" height='350px'>
                        <Link to={ link }>
                            <div id="tips-card-animation">
                                <div id="tips-card">
                                    <img id="tips-img" className="header-topic-img" width="100%" height="350px" src={ image } alt="Travel Tips" />
                                    <div className="header-text">
                                        <div className="home-tips-text">{ title }</div>
                                    </div>
                                    <div id="tips-description" />
                                </div>
                                <div className="home-tips-description">{ description }</div>
                            </div>
                        </Link>
                    </div>
                </div>
            </React.Fragment>
        )
    });
}


export default Home;
